
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[identifier]/created",
      function () {
        return require("private-next-pages/[identifier]/created/index.ts");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[identifier]/created"])
      });
    }
  